<template>
	<template-base class="containerCard">
		<div class="d-flex">
			<h1 class="align-self-center">Grupos de variáveis</h1>
			<div class="spacer"></div>
			<button
				v-if="possuiPermissao('GER_I_GRUPOS_VARIAVEIS')"
				class="btn btn-success text-white align-self-center"
				:data-cy="`Adicionar grupo`"
				id="nome"
				@click="adicionar"
			>
				Adicionar grupo principal
			</button>
		</div>
		<form @submit.prevent.stop="search">
			<div class="container-pesquisa mb-1">
				<div class="input-group">
					<div class="input-group-prepend">
						<div class="input-group-text">Pesquisar</div>
					</div>
					<input
						type="search"
						class="form-control"
						aria-label="Filtrar por texto"
						v-model="query"
						placeholder="Digite para filtrar por grupos"
						data-cy="Pesquisa"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-primary px-4"
							type="submit"
							data-cy="Pesquisar"
						>
							<SearchIcon/>
						</button>
					</div>
				</div>
			</div>
		</form>
		<div class="mt-2">
			<Tree
				controls
				:loading="loading"
				:items="items"
				@cancelar-novo="cancelarNovo"
				@apagar="apagar"
				@adicionado-novo="adicionadoNovo"
				@edicao-grupo="mostrarEdicao"
			/>
		</div>
		<div>
			<b-modal ref="modalEdicao" hide-footer hide-header>
				<div class="d-block mb-2">
					<label>Nome:</label>
					<b-form-input
						type="text"
						v-model="grupoInput"
						:state="inputValidacao"
					>
					</b-form-input>
					<b-form-invalid-feedback v-if="!grupoInput">
						O campo nome é obrigatório!
					</b-form-invalid-feedback>
				</div>
				<label>Grupo *</label>
					<vue-multi-select
						class="multi-100 mb-2"
						v-model="grupoSelecionado"
						search
						historyButton
						:options="{ multi: false, labelName: 'nome' }"
						:selectOptions="grupoOpcoes"
						:btnLabel="() => grupoSelecionado[0] ? grupoSelecionado[0].nome : 'Selecione'"
					/>
				<label>Área *</label>
					<vue-multi-select
						class="multi-100"
						v-model="areaSelecionada"
						@input="checarArea"
						search
						historyButton
						:options="{ multi: false, labelName: 'nome' }"
						:selectOptions="areasOpcoes"
						:btnLabel="() => areaSelecionada[0] ? areaSelecionada[0].nome : 'Selecione'"
					/>
				<label class="mt-2">Máquina *</label>
					<vue-multi-select
						class="multi-100"
						:class="{ 'btn-is-invalid': !maquinaSelecionada[0] && areaSelecionada[0] }"
						:disabled="!areaSelecionada[0]"
						v-model="maquinaSelecionada"
						search
						historyButton
						:options="{ multi: false, labelName: 'nome' }"
						:selectOptions="!areaSelecionada[0] ? undefined : maquinasOpcoes"
						:btnLabel="() => maquinaSelecionada[0] ? maquinaSelecionada[0].nome : 'Selecione'"
					/>
					<span v-if="!maquinaSelecionada[0] && areaSelecionada[0]" class="invalid-select">
						Selecione uma máquina
					</span>
				<b-button class="mt-3 float-left" variant="secondary" @click="fecharModal">Fechar</b-button>
				<b-button
					class="mt-3 ml-1 float-right"
					variant="success"
					@click="salvarEdicao"
					:disabled="!inputValidacao || (!maquinaSelecionada[0] && !!areaSelecionada[0])"
					>Salvar
				</b-button>
			</b-modal>
		</div>
	</template-base>
</template>

<script>
	function options (timeout) {
		return {
			timeout: timeout || 2000,
			showProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		};
	}

	import TemplateBase from "@/templates/Base";
	import Tree from "@/components/TreeGrupo";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import { GruposService } from "../../services/grupos";
	import { MaquinasService } from "../../services/maquinas";
	import { AreasService } from "../../services/areas";
	import { possuiPermissao } from "../../helpers/permissions";

	export default {
		components: {
			TemplateBase,
			Tree,
			VueMultiSelect
		},

		data () {
			return {
				items: [],
				query: "",

				grupos: [],
				areas: [],
				maquinas: [],

				loading: false,
				criandoItem: false,

				mapa: false,

				gruposService: new GruposService(),
				areasService: new AreasService(),
				maquinasService: new MaquinasService(),

				grupoSelecionado: [],
				grupoOpcoes: [],
				grupoInput: "",
				grupoItem: null,

				areaSelecionada: [],
				areasOpcoes: [],

				maquinaSelecionada: [],
				maquinasOpcoes: [],

				saving: false,
				possuiPermissao
			};
		},

		computed: {
			inputValidacao () {
				return this.grupoInput.length > 0;
			}
		},

		async mounted () {
			this.treeGrupos("");

			this.maquinas = (await this.maquinasService.listMachines()) || [];
			this.areas = (await this.areasService.listAreas()) || [];
		},

		methods: {
			mostrarEdicao (item) {
				this.grupoOpcoes = this.grupos.filter(g => g.id != item.id);
				this.grupoSelecionado = this.grupoOpcoes.filter(g => g.id === item.id_grupo_pai);
				this.grupoInput = item.nome;
				this.grupoItem = item;

				const maquina = this.maquinas.find(m => m.id === item.id_maquina);

				this.areasOpcoes = this.areas;
				this.areaSelecionada = this.areasOpcoes.filter(a => maquina && a.id === maquina.id_area);

				this.checarArea();
				this.maquinaSelecionada = this.maquinasOpcoes.filter(m => maquina && m.id === maquina.id);

				this.$refs.modalEdicao.show();
			},

			checarArea () {
				if (this.areaSelecionada[0])
					this.maquinasOpcoes = this.maquinas.filter(m => this.areaSelecionada[0] && m.id_area == this.areaSelecionada[0].id);
			},

			async salvarEdicao () {
				this.saving = true;

				const payload = {
					nome: this.grupoInput,
					id_grupo_pai: this.grupoSelecionado[0] ? this.grupoSelecionado[0].id : null,
					id_maquina: this.maquinaSelecionada[0] ? this.maquinaSelecionada[0].id : null
				};

				await this.gruposService.updateGroups(this.grupoItem.id, payload);

				this.grupoItem = null;
				this.treeGrupos();

				this.$snotify.async("Aguarde...", "Salvando", () => new Promise((resolve, reject) => {
					setTimeout(() => resolve({
						title: "Sucesso!",
						body: "Grupo salvo",
						config: options()
					}));
					setTimeout(() => reject({
						title: "Erro ao salvar",
						config: options()
					}));
				}));

				this.fecharModal();
			},

			fecharModal () {
				this.$refs.modalEdicao.hide();
			},

			async treeGrupos (query) {
				this.loading = true;

				const grupos = (await this.gruposService.listGroups(query)) || [];

				const treeNodes = {};
				const forest = [];

				for (const grupo of grupos) {
					if (grupo.id_grupo_pai == null || !grupos.find(g => g.id === grupo.id_grupo_pai))
						forest.push(grupo);
					else if (treeNodes[grupo.id_grupo_pai])
						treeNodes[grupo.id_grupo_pai].push(grupo);
					else
						treeNodes[grupo.id_grupo_pai] = [grupo];
				}

				for (const grupo of grupos) {
					delete grupo.createdAt;
					delete grupo.updatedAt;

					grupo.opened = false;
					grupo.checked = false;
					grupo.editing = false;
					grupo.children = treeNodes[grupo.id] || [];

					forest.sort((a,b) => a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0);
				}

				for (const grupo of grupos) {
					grupo.children.sort((a, b) => {
						const folder = (b.children.length > 0) - (a.children.length > 0);
						return folder ? folder : a.nome.localeCompare(b.nome);
					});
				}

				this.query = query;
				this.grupos = grupos;
				this.items = forest;
				this.loading = false;
			},

			async search () {
				await this.treeGrupos(this.query);
				for (const grupo of this.grupos)
					grupo.opened = !!this.query;
			},

			async adicionar () {
				if (this.criandoItem) return;
				this.criandoItem = true;
				const item = {
					children: [],
					editing: true,
					id: null,
					id_grupo_pai: null,
					nome: "",
					opened: false
				};
				this.grupos.push(item);
				this.items.push(item);
			},

			async apagar (id) {
				const idx = this.items.findIndex((c) => c.id === id);
				if (idx >= 0) this.items.splice(idx, 1);

				const idx2 = this.grupos.findIndex((c) => c.id === id);
				if (idx2 >= 0) this.grupos.splice(idx2, 1);
			},

			cancelarNovo () {
				this.criandoItem = false;
				this.items.pop();
				this.grupos.pop();
			},

			adicionadoNovo () {
				this.criandoItem = false;
			}
		}
	};
</script>

<style scoped>
	.containerCard {
		max-width: 90.5%;
	}
</style>

<style>
	.invalid-select {
		width: 100%;
		margin-top: 0.25rem;
		font-size: 80%;
		color: #dc3545;
		display: block;
	}

	.form-control {
		height: auto !important;
	}

	.btn-is-invalid > button {
		border-color: #dc3545 !important;
	}

	.btn-is-invalid > button:focus {
		border-color: #d1c4c5 !important;
		-webkit-box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
		box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
	}
</style>

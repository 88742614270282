<template>
	<li :class="liClass">
		<div v-if="!item.editing" @click="toggle" class="d-flex align-items-center">
			<input type="checkbox" class="custom-checkbox" :checked="item.checked !== false" :disabled="item.checked === null" v-if="selectable" @click.stop @change="check(item)" :data-cy="`check${item.nome}`">
			<span :class="{ bold: isFolder }" :data-cy="item.nome" @dblclick="editar">{{ item.nome }}</span>
			<button class="nao-botao ml-1 mt-1" v-if="controls && possuiPermissao('GER_I_GRUPOS_VARIAVEIS')" @click.stop="adicionar" :data-cy="`${item.nome}Adicionar`"><file-plus-icon size="16"/></button>
			<button class="nao-botao ml-1 mt-1" v-if="controls && possuiPermissao('GER_U_GRUPOS_VARIAVEIS')" @click.stop="() => $emit('edicao-grupo', item)" :data-cy="`${item.nome}Editar`"><edit-icon size="16"/></button>
			<button class="nao-botao ml-1 mt-1" v-if="controls && possuiPermissao('GER_D_GRUPOS_VARIAVEIS')" @click.stop="remover" :data-cy="`${item.nome}Excluir`"><trash-2-icon size="16"/></button>
		</div>
		<div v-else class="d-flex align-items-center">
			<form @submit.prevent.stop="salvar">
				<input class="ml-1" ref="edicao" :class="{ bold: isFolder }" v-model="item.nome" :disabled="saving" required/>
				<button type="submit" class="nao-botao ml-1 mt-1" :disabled="saving"><save-icon size="16" :data-cy="`${item.nome}Salvar`"/></button>
			</form>
			<button type="button" class="nao-botao ml-1 mt-1" @click="cancelar" :disabled="saving"><x-icon size="16" :data-cy="`${item.nome}Cancelar`"/></button>
		</div>
		<ul v-if="isFolder" v-show="item.opened" @click.stop>
			<tree-node
				class="ml-1 mt-1"
				:controls="controls"
				:selectable="selectable"
				v-for="i in item.children" :key="i.id"
				:item="i"
				:depth="depth+1"
				@cancelar-novo="cancelarNovo"
				@apagar="apagar"
				@adicionado-novo="adicionadoNovo"
				@edicao-grupo="(item) => $emit('edicao-grupo', item)"
			/>
		</ul>
	</li>
</template>

<script>
	function options (timeout) {
		return {
			timeout: timeout || 2000,
			showProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true
		};
	}

	import { copyProps } from "@/helpers/common";
	import { GruposService } from "../../services/grupos";
	import { possuiPermissao } from "../../helpers/permissions";

	export default {
		name: "tree-node",
		props: {
			item: Object,
			depth: Number,
			controls: {
				type: Boolean,
				default: false
			},
			selectable: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				copy: {},
				saving: false,
				criandoItem: false,

				gruposService: new GruposService(),
				possuiPermissao
			};
		},

		computed: {
			isFolder () {
				return (this.item.children && this.item.children.length);
			},

			liClass () {
				return !this.isFolder ? "file"
					: this.item.opened ? "folder_open"
						: "folder";
			}
		},

		methods: {
			toggle () {
				this.item.opened = !this.item.opened;
			},

			check (item, v) {
				const val = item.checked = (typeof v === "undefined" ? !item.checked : v);
				if (item.children) {
					for (const it of item.children) {
						this.check(it, val === false ? val : null);
					}
				}
			},

			cancelar () {
				if (!this.item.id) this.$emit("cancelar-novo");
				else {
					this.item.editing = false;
					this.item.id = this.copy.id;
					this.item.nome = this.copy.nome;
					this.item.id_grupo_pai = this.copy.id_grupo_pai;
					this.item.id_maquina = this.copy.id_maquina;
				}
			},

			cancelarNovo () {
				this.criandoItem = false;
				this.item.children.pop();
			},

			apagar (id) {
				const idx = this.item.children.findIndex(c => c.id === id);
				if (idx >= 0) {
					this.item.children.splice(idx, 1);
				}
			},

			async adicionar () {
				if (this.criandoItem) return;
				this.item.opened = true;
				this.item.children.push({
					children: [],
					editing: true,
					id: null,
					id_grupo_pai: this.item.id,
					nome: "",
					opened: false,
					id_maquina: null
				});
				this.criandoItem = true;
			},

			adicionadoNovo () {
				this.criandoItem = false;
			},

			async editar () {
				this.copy = copyProps(this.item)(["id", "nome", "id_grupo_pai", "id_maquina"]);
				this.item.editing = true;
				setTimeout(() => this.$refs.edicao.focus());
			},

			salvar () {
				this.saving = true;
				this.$snotify.async("Aguarde...", "Salvando", () => new Promise((resolve, reject) => {
					const payload = copyProps(this.item)(["id", "nome", "id_grupo_pai", "id_maquina"]);
					const req = payload.id
						? this.gruposService.updateGroups(payload.id, payload)
						: this.gruposService.addGroups(payload);
					req.then(res => {
						this.saving = false;
						this.item.id = res.id;
						this.item.nome = res.nome;
						this.item.id_grupo_pai = res.id_grupo_pai;
						this.item.id_maquina = res.id_maquina;
						this.item.editing = false;

						if (!payload.id)
							this.$emit("adicionado-novo");

						resolve({
							title: "Sucesso!",
							body: "Grupo salvo",
							config: options()
						});
					}).catch(e => {
						this.saving = false;
						reject({
							title: "Erro ao salvar",
							body: e.response.data ? e.response.data.error : e.toString(),
							config: options(10000)
						});
					});
				}));
			},

			async remover () {
				const doDelete = () => this.gruposService.deleteGroups(this.item.id)
					.then(res => res.data.error ? Promise.reject(res.data.error) : res.data)
					.catch(reason => {
						if (reason.response && reason.response.data && reason.response.data.error) {
							this.$swal.showValidationMessage("Falha ao excluir: " + (reason.response.data.error));
						}
					});

				// TODO: transformar em função auxiliar para evitar duplicação de código
				this.$swal.fire({
					title: "Você tem certeza?",
					text: `A remoção de ${this.item.nome} é irreversível`,
					type: "warning",
					showCancelButton: true,
					reverseButtons: true,
					focusCancel: true,
					confirmButtonColor: "#dc3545",
					confirmButtonText: "Remover",
					cancelButtonText: "Cancelar",
					allowOutsideClick: () => !this.$swal.isLoading(),
					showLoaderOnConfirm: true,
					preConfirm: doDelete
				}).then(res => {
					if (res.value) {
						this.$snotify.success("Grupo removido", "Sucesso!");
						this.$emit("apagar", this.item.id);
					}
				});
			}
		},

		mounted () {
			if (this.item.editing)
				this.$refs.edicao.focus();
		}
	};
</script>

<style scoped>
	.bold {
		font-weight: bold;
	}
	ul {
		padding-left: 10x;
		margin-left: 0px;
	}
	li {
		list-style: none;
		box-sizing: border-box;
		vertical-align: middle;
		position: relative;
	}
	li::before {
		content:'';
		position:absolute;
		top: -5px;
		left: -31px;
		border-left: 2px dashed #a2a5b5;
		width: 1px;
		height: 100%;
	}
	li:last-child::before {
		height: 20px;
	}
	li::after {
		content:'';
		position: absolute;
		border-top: 2px dashed #a2a5b5;
		top: 15px;
		left: -30px;
		width: 30px;
	}
	li div {
		display: inline-flex;
		cursor: pointer;
	}
	li div::before {
		content: ' ';
		width: 26px;
		height: 26px;
		background-size: contain;
		margin-right: 2px;
	}
	li.folder>div::before{
		background-image: url('./icons/folder.svg');
		background-repeat: no-repeat;
		min-width: 16px;
	}
	li.folder_open>div::before {
		background-image: url('./icons/folder-open.svg');
		background-repeat: no-repeat;
		min-width: 16px;
	}
	li.file>div::before {
		background-image: url('./icons/file.svg');
		background-repeat: no-repeat;
		min-width: 16px;
	}
	li.file>div {
		cursor: auto;
	}
	li div a {
		color: black;
	}

	.nao-botao {
		border: 0 !important;
		line-height: 27px;
	}

	.custom-checkbox {
		width: 20px;
		height: 20px;
		margin-right: .25rem;
	}

	@media (max-width: 790px) {
		ul {
			font-size: 16px;
		}
		input {
			height: 24px;
			width: 130px;
		}
		.btn.btn-light.ml-1 {
			font-size: 14px;
		}
		form {
			min-width: 280px;
		}
	}
</style>

<template>
	<ul v-if="loading">
		<tree-node-carregando
			v-for="item in carregando"
			:key="item.id"
			:item="item"
			:depth="0"
		/>
	</ul>
	<ul v-else-if="items.length > 0">
		<tree-node
			:controls="controls"
			:selectable="selectable"
			v-for="item in items"
			:key="item.id"
			:item="item"
			:depth="0"
			@cancelar-novo="$emit('cancelar-novo')"
			@apagar="(id) => $emit('apagar', id)"
			@edicao-grupo="(item) => $emit('edicao-grupo', item)"
			@adicionado-novo="$emit('adicionado-novo')"
		/>
	</ul>
	<ul v-else>
		<em>Grupo não encontrado</em>
	</ul>
</template>

<script>
	import TreeNode from "./TreeGrupo/TreeNode";
	import TreeNodeCarregando from "./TreeGrupo/TreeNodeCarregando";

	export default {
		components: {
			TreeNode,
			TreeNodeCarregando
		},
		name: "tree",
		props: {
			items: Array,
			loading: Boolean,
			controls: {
				type: Boolean,
				default: false
			},
			selectable: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				carregando: [
					{
						id: 1,
						children: [{ id: 2, children: [{ id: 3, children: [] }] }]
					},
					{ id: 5, children: [{ id: 6, children: [] }] }
				]
			};
		}
	};
</script>

<style scoped>
	ul {
		overflow-x: auto;
		padding: 0;
		font-size: 22px;
	}
</style>
